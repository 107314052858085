<template>
  <VCard class="step-essay">
    <header class="step__header">
      <StepToolbar :step="essay" />

      <VCardTitle class="lesson-title">
        {{ step.lesson_title }}
      </VCardTitle>

      <LessonMeeting :meeting="step.meeting" />

      <VCardText>
        <VRow>
          <VCol
            cols="12"
            lg="6"
          >
            <template v-if="essay.is_completed">
              <VSheet
                class="mb-4"
                color="rgba(0, 0, 0, 0.12)"
              >
                <VMenu v-if="step.attempts.length > 1">
                  <template #activator="{ on, attrs }">
                    <VListItem
                      dense
                      title="Все попытки по этому уроку"
                      text
                      v-bind="attrs"
                      v-on="on"
                    >
                      <VListItemIcon>
                        <VIcon>expand_more</VIcon>
                      </VListItemIcon>
                      <VListItemContent>
                        <VListItemTitle>
                          {{ $t('attempts.one') }}: <time :datetime="essay.completed_at">{{ formatDateTime(essay.completed_at) }}</time>
                        </VListItemTitle>
                      </VListItemContent>
                      <VListItemAction>
                        <VListItemActionText :class="`${getStatus(essay).color}--text`">
                          {{ $t(getStatus(essay).title) }}
                        </VListItemActionText>
                      </VListItemAction>
                    </VListItem>
                  </template>
                  <VList dense>
                    <template v-for="(attempt, idx) in step.attempts">
                      <VDivider
                        v-if="idx !== 0"
                        :key="`attempt${idx}`"
                      />
                      <VListItem
                        :key="attempt.id"
                        :value="attempt.id"
                        :to="{ query: { attempt: attempt.id } }"
                        exact
                      >
                        <VListItemContent>
                          <VListItemTitle>
                            {{ $t('attempts.one') }}:
                            <time :datetime="attempt.completed_at">{{ formatDateTime(attempt.completed_at) }}</time>
                          </VListItemTitle>
                        </VListItemContent>
                        <VListItemAction>
                          <VListItemActionText :class="`${getStatus(attempt).color}--text`">
                            {{ $t(getStatus(attempt).title) }}
                          </VListItemActionText>
                        </VListItemAction>
                      </VListItem>
                    </template>
                  </VList>
                </VMenu>
                <VListItem
                  v-else
                  dense
                >
                  <VListItemContent>
                    <VListItemTitle>
                      {{ $t('attempts.one') }}: <time :datetime="essay.completed_at">{{ formatDateTime(essay.completed_at) }}</time>
                    </VListItemTitle>
                  </VListItemContent>
                  <VListItemAction>
                    <VListItemActionText :class="`${getStatus(essay).color}--text`">
                      {{ $t(getStatus(essay).title) }}
                    </VListItemActionText>
                  </VListItemAction>
                </VListItem>
              </VSheet>

              <p>
                <label>{{ $t('time_to_attempt.one') }}: </label>
                <time>{{ spentTime }}</time>
              </p>

              <p v-if="!essay.is_exercise && essay.mark">
                <label>{{ $t('marks.one') }}: </label>
                <span :class="[essay.is_accepted ? 'success--text': 'error--text']">{{ essay.mark }}</span>
              </p>

              <template v-if="!step.is_accepted && (!essay.is_started || essay.is_evaluated)">
                <template v-if="essay.settings.max_attempts_total > 0">
                  <p v-if="step.attempts_left_total > 1">
                    {{ $t('attempts_left_total.one', {
                      count: step.attempts_left_total,
                      all: essay.settings.max_attempts_total,
                      unit: selectUnit(essay.settings.max_attempts_total, [
                        $t('attempts.genitive_one'),
                        $t('attempts.genitive'),
                        $t('attempts.genitive'),
                      ]).toLowerCase(),
                    }) }}
                  </p>
                  <p
                    v-else-if="step.attempts_left_total === 1"
                    class="warning--text"
                  >
                    {{ $t('last_attempt_left_total.one') }}.
                  </p>
                  <p
                    v-else
                    class="error--text"
                  >
                    {{ $t('no_attempts_left_total.one') }}.
                  </p>
                </template>
                <template v-if="essay.settings.max_attempts_per_day > 0">
                  <p v-if="step.attempts_left_today > 1">
                    {{ $t('attempts_left_today.one', {
                      count: step.attempts_left_today,
                      all: essay.settings.max_attempts_per_day,
                      unit: selectUnit(essay.settings.max_attempts_per_day, [
                        $t('attempts.genitive_one'),
                        $t('attempts.genitive'),
                        $t('attempts.genitive'),
                      ]).toLowerCase(),
                    }) }}
                  </p>
                  <p
                    v-else-if="step.attempts_left_today === 1"
                    class="warning--text"
                  >
                    {{ $t('last_attempt_left_today.one') }}.
                  </p>
                  <p
                    v-else
                    class="error--text"
                  >
                    {{ $t('no_attempts_left_today.one') }}.
                  </p>
                </template>
              </template>
            </template>
          </VCol>

          <VCol
            cols="12"
            lg="6"
          >
            <p
              v-if="essay.is_required"
              class="warning--text"
              small
            >
              {{ $t('required.one') }}!
            </p>
            <p
              v-else
              class="info--text"
              small
            >
              {{ $t('optional.one') }}.
            </p>
            <p
              v-if="essay.is_exercise"
              class="success--text"
              small
            >
              {{ $t('exercise.one') }}.
            </p>

            <VAlert
              v-if="step.is_summative && isAvailableSummativeRaw"
              type="warning"
            >
              {{ isAvailableSummativeRaw }}
            </VAlert>

            <p v-if="essay.settings.max_minutes_per_attempt > 0">
              {{ $t('$app.essay_execution_time') }} <time>{{ formatInt(essay.settings.max_minutes_per_attempt, [$t('minutes.accusative_one'), $t('minutes.accusative_two'), $t('minutes.accusative_many')]) }}</time>.
            </p>

            <UPrintResults
              v-if="essay.is_completed"
              :html="`/training/essays/${essay.id}/results?_fmt=html`"
              :pdf="`/training/essays/${essay.id}/results?_fmt=pdf`"
              :odt="`/training/essays/${essay.id}/results?_fmt=odt`"
            />
          </VCol>
        </VRow>
      </VCardText>

      <VCardText
        v-if="lesson.settings.is_public_description"
        class="text--primay"
      >
        <div
          class="rte-content"
          v-html="lesson.description"
        />
      </VCardText>

      <VCardActions
        v-if="permissions.can_write && !session.is_completed"
        class="actions"
      >
        <template v-if="essay.is_exercise && essay.is_completed">
          <VBtn
            :large="$vuetify.breakpoint.lgAndUp"
            color="primary"
            @click.prevent="start"
          >
            <VIcon left>
              replay
            </VIcon> {{ $t('again.one') }}
          </VBtn>
        </template>

        <template v-else-if="essay.is_accepted">
          <VBtn
            v-if="!step.is_completed && step.attempts_left_total > 0 && step.attempts_left_today > 0"
            :large="$vuetify.breakpoint.lgAndUp"
            color="primary"
            @click.prevent="start"
          >
            <VIcon left>
              replay
            </VIcon> {{ $t('again.one') }}
          </VBtn>
        </template>

        <template v-else-if="isEvaluated">
          <VBtn
            v-if="!step.is_completed && step.attempts_left_total > 0 && step.attempts_left_today > 0"
            :large="$vuetify.breakpoint.lgAndUp"
            color="primary"
            @click.prevent="start"
          >
            <VIcon left>
              replay
            </VIcon> {{ $t('again.one') }}
          </VBtn>
        </template>

        <template v-else-if="essay.is_completed">
          <!--  -->
        </template>

        <template v-else-if="essay.is_started">
          <!--  -->
        </template>

        <template v-else>
          <VBtn
            v-if="step.attempts_left_total > 0 && step.attempts_left_today > 0"
            :large="$vuetify.breakpoint.lgAndUp"
            color="primary"
            @click.prevent="start"
          >
            <VIcon left>
              play_arrow
            </VIcon> {{ $t('start.one') }}
          </VBtn>
        </template>

        <VBtn
          v-if="!step.is_required && !step.is_completed && !step.is_skipped"
          :large="$vuetify.breakpoint.lgAndUp"
          :loading="isSkipping"
          outlined
          @click.prevent="skip"
        >
          <VIcon left>
            skip_next
          </VIcon> {{ $t('skip.one') }}
        </VBtn>
      </VCardActions>
    </header>
    <VDivider />

    <div
      v-if="essay.is_started"
      class="step__body"
    >
      <VCardText v-if="essay.is_evaluated">
        <p v-if="essay.comments">
          <label class="font-weight-bold">{{ $t('$app.teacher_comments') }}:</label>
          <MarkdownContent :content="essay.comments" />
        </p>

        <p v-if="essay.comments_attachments">
          <label>{{ $t('attached_files.' + (essay.comments_attachments.length > 1 ? 'other' : 'one')) }}:</label>
          <ul>
            <li
              v-for="file in essay.comments_attachments"
              :key="file.id"
            >
              <label class="d-sr-only">Имя файла:</label>
              <a
                :href="`/${file.uri}`"
                target="_blank"
                rel="noopener noreferrer"
              >{{ file.title }}</a>
              <br><label>Размер:</label> {{ formatSize(file.size) }}
            </li>
          </ul>
        </p>
      </VCardText>

      <template v-if="essay.is_completed">
        <VCardTitle
          tag="h2"
          class="headline"
        >
          {{ $t('answers.other') }}:
        </VCardTitle>

        <VCardText v-if="essay.tasks_count">
          <VRow>
            <VCol
              v-for="(task, idx) in essay.tasks"
              :key="task.id"
              cols="12"
            >
              <VCard
                outlined
                class="task"
              >
                <header class="task__header">
                  <div class="task__title headline">
                    {{ $t('tasks.one') }} № {{ idx + 1 }}
                  </div>
                </header>
                <VCardText class="text--primary">
                  <label class="d-sr-only">{{ $t('tasks.one') }}:</label>
                  <div
                    v-if="task.question"
                    class="rte-content"
                    v-html="task.question"
                  />
                  <VAlert
                    v-else
                    dense
                  >
                    Тест задания почему-то пуст,
                    <RouterLink :to="{ name: 'messenger' }">
                      обратитесь к&nbsp;администратору
                    </RouterLink>.
                  </VAlert>

                  <label class="d-block mt-4 headline">{{ $t('answers.one') }}:</label>
                  <MarkdownContent
                    v-if="task.user_answer"
                    :content="task.user_answer"
                  />
                  <VAlert
                    v-else
                    dense
                  >
                    Ответа нет.
                  </VAlert>

                  <div
                    v-if="task.user_attachments_count"
                    role="list"
                  >
                    <label>{{ $t('attached_files.other') }}:</label>

                    <ul>
                      <li
                        v-for="(file, j) in task.user_attachments"
                        :key="j"
                      >
                        <audio
                          v-if="file.mime_type.startsWith('audio/')"
                          :src="`/${file.uri}`"
                          controls
                        >
                          <a
                            :href="`/${file.uri}`"
                            target="_blank"
                            rel="noopener noreferrer"
                          >{{ file.title }}</a>
                        </audio>

                        <template v-else>
                          <label class="d-sr-only">Имя файла:</label>
                          <a
                            :href="`/${file.uri}`"
                            target="_blank"
                            rel="noopener noreferrer"
                          >{{ file.title }}</a>
                          <br><label>Размер:</label> {{ formatSize(file.size) }}
                        </template>
                      </li>
                    </ul>
                  </div>
                  <VAlert
                    v-else
                    dense
                  >
                    {{ $t('files.many') }} {{ $t('no.one') }}.
                  </VAlert>
                </VCardText>
              </VCard>
            </VCol>
          </VRow>
        </VCardText>
        <VAlert
          v-else
          dense
        >
          Задания почему-то нет,
          <RouterLink :to="{ name: 'messenger' }">
            обратитесь к&nbsp;администратору
          </RouterLink>.
        </VAlert>
      </template>

      <template v-else-if="permissions.can_write">
        <VCardTitle tag="h2">
          {{ essay.tasks_count > 1 ? $t('tasks.other') : $t('tasks.one') }}:
        </VCardTitle>

        <template v-if="essay.tasks_count > 0 && answers.length">
          <TrainingTimer
            v-if="essay.settings.max_minutes_per_attempt"
            class="mb-4"
            :on="timerOn"
            :buffer-value="maxSecondsPerAttempt"
            :seconds="leftTime"
            @stop="stop"
          />
          <VCardText>
            <VRow>
              <VCol
                v-for="(task, idx) in essay.tasks"
                :key="task.id"
                cols="12"
              >
                <VCard
                  outlined
                  class="task"
                >
                  <header class="task__header">
                    <div class="task__title headline">
                      {{ $t('tasks.one') }} № {{ idx + 1 }}
                    </div>
                  </header>

                  <VCardText class="text--primary">
                    <label class="d-sr-only">{{ $t('tasks.one') }}:</label>
                    <div
                      v-if="task.question"
                      class="rte-content"
                      v-html="task.question"
                    />
                    <VAlert
                      v-else
                      dense
                    >
                      Тест задания почему-то пуст,
                      <RouterLink :to="{ name: 'messenger' }">
                        обратитесь к&nbsp;администратору
                      </RouterLink>.
                    </VAlert>

                    <MarkdownEditor
                      v-model.trim="answers[idx].value"
                      class="mt-8"
                    />
                    <FileList
                      v-model="answers[idx].files"
                      mode="cards"
                    />
                  </VCardText>

                  <VCardActions>
                    <AudioRecorder @save="addAudioAnswer($event, idx)" />
                    <VBtn
                      :title="$t('attach_files.one')"
                      text
                      tag="label"
                    >
                      <VIcon
                        left
                        size="24"
                      >
                        attach_file
                      </VIcon>
                      <span>{{ $t('attach_files.one') }}</span>
                      <input
                        v-file="answers[idx].files"
                        type="file"
                        multiple
                        :accept="accept"
                        :size="maxFilesize"
                        hidden
                      >
                    </VBtn>
                  </VCardActions>
                </VCard>
              </VCol>
            </VRow>
          </VCardText>
        </template>
        <VAlert
          v-else
          dense
        >
          Задания почему-то нет,
          <RouterLink :to="{ name: 'messenger' }">
            обратитесь к&nbsp;администратору
          </RouterLink>.
        </VAlert>

        <VSpacer />
        <VCardActions class="actions">
          <VBtn
            :large="$vuetify.breakpoint.lgAndUp"
            :loading="isSubmitting"
            color="success"
            @click.prevent="save"
          >
            <VIcon left>
              done
            </VIcon> {{ $t('submit.one') }}!
          </VBtn>
        </VCardActions>
      </template>
    </div>
  </VCard>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

import { startEssay, stopEssay, updateEssay } from '@/api/api'
import AudioRecorder from '@components/AudioRecorder.vue'
import FileList from '@/components/FileList.vue'
import MarkdownEditor from '@/components/MarkdownEditor.vue'
import MarkdownContent from '@/components/MarkdownContent.vue'
import { accept } from '@/utils/files'
import trainingStep from '@/mixins/trainingStep'
import { formatSize } from '@/utils/common'
import * as actions from '@/store/actions/types'
import * as getters from '@/store/getters/types'
import UPrintResults from '@components/UPrintResults.vue'
import StepToolbar from '@components/StepToolbar.vue'
import LessonMeeting from '@components/LessonMeeting.vue'

export default {
  name: 'TrainingEssay',

  components: {
    FileList,
    MarkdownEditor,
    MarkdownContent,
    AudioRecorder,
    StepToolbar,
    LessonMeeting,
    UPrintResults
  },

  mixins: [trainingStep],

  data () {
    return {
      isSubmitting: false,
      answers: [],
      accept
    }
  },

  computed: {
    ...mapGetters({
      essay: getters.ESSAY,
      lesson: getters.LESSON,
      maxFilesize: getters.MAX_FILE_SIZE,
      permissions: getters.PERMISSIONS
    }),

    isEvaluated () {
      // Если последняя попытка проверена будем считать что задания проверены
      if (this.step && this.step.attempts) {
        return this.step.attempts.every(attempt => attempt.is_evaluated)
      }

      return false
    }
  },

  created () {
    this.register()
  },

  beforeDestroy () {
    this.unregister()
  },

  methods: {
    ...mapActions({
      createNotification: actions.CREATE_NOTIFICATION,
      updateStep: actions.UPDATE_STEP
    }),

    formatSize,

    // начать попытку
    async start () {
      const data = new FormData()
      data.append('step', this.step.id)

      const response = await startEssay(data)
      this.updateStep(response)

      if (this.essay.tasks_count > 0) {
        this.answers = this.essay.tasks.map(task => ({
          task: task.id,
          files: [],
          value: ''
        }))
      }
    },

    // прервать попытку
    async stop () {
      const data = new FormData()
      data.append('_confirm', 1)

      const response = await stopEssay(this.essay.id, data)
      this.updateStep(response)
      this.answers = []
    },

    // сохранить результат
    async save () {
      try {
        this.isSubmitting = true
        const data = new FormData()

        if (this.essay.tasks_count > 0 && this.answers.length) {
          this.answers.forEach(answer => {
            if (!answer.files.length && !answer.value) {
              throw new Error('Выполните практическое задание!')
            }

            data.append('task', answer.task)
            data.append(`user_answer[${answer.task}]`, answer.value)
            answer.files.forEach(file => data.append(`user_attachment[${answer.task}]`, file, file.name))
          })
        }

        const response = await updateEssay(this.essay.id, data)
        this.updateStep(response)
      } catch (err) {
        this.createNotification({
          msg: err.message,
          type: 'error'
        })
      }

      this.$nextTick(() => { this.isSubmitting = false })
    },

    // Добавить аудио ответ
    addAudioAnswer (file, idx) {
      this.answers[idx].files.push(file)
    },

    async register () {
      if (this.essay.tasks_count > 0) {
        this.answers = this.essay.tasks.map(task => ({
          task: task.id,
          files: [],
          value: ''
        }))
      }
    },

    unregister () {
      this.answers = []
    }
  }
}
</script>
